<template>
  <component :is="cameraDisplayView"></component>
</template>

<script>
import { mapState } from 'vuex';
import store from '@/store';
import CameraThirdPartyView from '@/components/CameraThirdPartyView.vue';
import CameraTimelapseView from '@/components/CameraTimelapseView.vue';

export default {
  name: 'CameraHome',
  components: {
    CameraThirdPartyView,
    CameraTimelapseView,
  },
  computed: {
    ...mapState('camera', ['camera']),
    cameraDisplayView() {
      return `camera-${this.camera.type}-view`;
    },
  },

  beforeRouteUpdate(to, from, next) {
    let now = new Date();

    if (this.camera.type === 'third-party') {
      return next();
    }

    if (this.camera.last_capture_at) {
      now = new Date(this.camera.last_capture_at);
    }

    this.$store.dispatch('photo/setFilterDate', now);
    next();
  },

  beforeRouteEnter(to, from, next) {
    let now = new Date();

    if (store.state.camera.camera.type === 'third-party') {
      return next();
    }

    if (store.state.camera.camera.last_capture_at) {
      now = new Date(store.state.camera.camera.last_capture_at);
    }

    store.dispatch('photo/setFilterDate', now);
    next();
  },
};
</script>
