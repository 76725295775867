var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppLayout",
    {
      scopedSlots: _vm._u([
        {
          key: "actionbar",
          fn: function() {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-between d-lg-none"
                },
                [
                  _c(
                    "div",
                    { staticClass: "mx-auto" },
                    [
                      _c(
                        "b-button-group",
                        { attrs: { size: "sm" } },
                        [
                          _c("b-button", { attrs: { variant: "primary" } }, [
                            _vm._v(_vm._s(_vm.$t("lang.image.msg")))
                          ]),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                to: {
                                  name: "camera-video",
                                  params: { id: _vm.camera.camera.id }
                                },
                                variant: "outline-primary"
                              }
                            },
                            [_vm._v("Timelapse")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass:
                        "btn-circle btn-line p-0 d-flex align-items-center justify-content-center flex-col d-lg-none",
                      attrs: { variant: _vm.showSubmenu ? "primary" : "white" },
                      on: {
                        click: function($event) {
                          _vm.showSubmenu = !_vm.showSubmenu
                        }
                      }
                    },
                    [_c("i", { staticClass: "tmlps-icn-back h3 mb-0" })]
                  )
                ],
                1
              ),
              _c("div", { staticClass: "d-lg-none divider mt-2" }),
              _c(
                "div",
                {
                  staticClass:
                    "mt-2 d-flex flex-wrap justify-content-between flex-grow-1"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex-grow-1 d-lg-flex justify-content-md-start"
                    },
                    [
                      !_vm.isPublic
                        ? _c("TenantLogo", { staticClass: "d-none d-lg-block" })
                        : _vm._e(),
                      _c("CameraSelectorButton", {
                        staticClass: "d-flex flex-column ml-lg-3",
                        attrs: {
                          active: _vm.activeDrawer === "camera-drawer",
                          camera: _vm.camera.camera,
                          disabled: _vm.isPublic
                        },
                        on: {
                          click: function($event) {
                            return _vm.toggleDrawer("camera-drawer")
                          }
                        }
                      }),
                      _c(
                        "b-button-group",
                        { staticClass: "d-none ml-lg-3 d-lg-block" },
                        [
                          _c("b-button", { attrs: { variant: "primary" } }, [
                            _vm._v(_vm._s(_vm.$t("lang.image.msg")))
                          ]),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                to: {
                                  name: "camera-video",
                                  params: {
                                    id: _vm.camera.camera.id,
                                    esPublico: _vm.isPublic
                                      ? "public"
                                      : undefined
                                  }
                                },
                                variant: "outline-primary"
                              }
                            },
                            [_vm._v("Timelapse")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-center" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass:
                            "btn-transparent btn-circle btn-line mb-1",
                          attrs: { variant: "outline-primary" },
                          on: {
                            mouseover: function($event) {
                              _vm.hover.img1 = true
                            },
                            mouseleave: function($event) {
                              _vm.hover.img1 = false
                            },
                            click: _vm.photoUltima
                          }
                        },
                        [
                          _c("img", {
                            class: _vm.hover.img1 ? "colorBlanco" : "",
                            attrs: {
                              src: require("@/assets/iconos/1.svg"),
                              alt: ""
                            }
                          })
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass:
                            "btn-transparent btn-circle btn-line mb-1",
                          staticStyle: { "margin-left": "0.5rem !important" },
                          attrs: { variant: "outline-primary" },
                          on: {
                            mouseover: function($event) {
                              _vm.hover.img2 = true
                            },
                            mouseleave: function($event) {
                              _vm.hover.img2 = false
                            },
                            click: _vm.photoSiguiente
                          }
                        },
                        [
                          _c("img", {
                            class: _vm.hover.img2 ? "colorBlanco" : "",
                            attrs: {
                              src: require("@/assets/iconos/2.svg"),
                              alt: ""
                            }
                          })
                        ]
                      ),
                      _c("PhotoDateSelectorButton", {
                        staticStyle: { "margin-left": "0.5rem !important" },
                        attrs: {
                          active: _vm.activeDrawer === "photo-drawer",
                          photo: _vm.selectedPhoto
                        },
                        on: {
                          click: function($event) {
                            return _vm.toggleDrawer("photo-drawer")
                          }
                        }
                      }),
                      _c(
                        "b-button",
                        {
                          staticClass:
                            "btn-transparent btn-circle btn-line mb-1",
                          staticStyle: { "margin-left": "0.5rem !important" },
                          attrs: { variant: "outline-primary" },
                          on: {
                            mouseover: function($event) {
                              _vm.hover.img3 = true
                            },
                            mouseleave: function($event) {
                              _vm.hover.img3 = false
                            },
                            click: _vm.photoAnterior
                          }
                        },
                        [
                          _c("img", {
                            class: _vm.hover.img3 ? "colorBlanco" : "",
                            attrs: {
                              src: require("@/assets/iconos/3.svg"),
                              alt: ""
                            }
                          })
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass:
                            "btn-transparent btn-circle btn-line mb-1",
                          staticStyle: { "margin-left": "0.5rem !important" },
                          attrs: { variant: "outline-primary" },
                          on: {
                            mouseover: function($event) {
                              _vm.hover.img4 = true
                            },
                            mouseleave: function($event) {
                              _vm.hover.img4 = false
                            },
                            click: _vm.photoPrimera
                          }
                        },
                        [
                          _c("img", {
                            class: _vm.hover.img4 ? "colorBlanco" : "",
                            attrs: {
                              src: require("@/assets/iconos/4.svg"),
                              alt: ""
                            }
                          })
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showSubmenu,
                          expression: "showSubmenu"
                        }
                      ],
                      staticClass:
                        "pt-2 mt-2 d-lg-flex flex-grow-1 pt-lg-0 mt-lg-0 navbar-buttons"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-around justify-content-lg-start"
                        },
                        [
                          _c("CompareButton", {
                            staticClass: "ml-lg-3",
                            on: {
                              click: function($event) {
                                return _vm.$router.push({
                                  name: "camera-compare",
                                  params: {
                                    esPublico: _vm.isPublic
                                      ? "public"
                                      : undefined
                                  }
                                })
                              }
                            }
                          }),
                          !_vm.isPublic
                            ? _c("DownloadButton", {
                                staticClass: "ml-lg-3",
                                on: {
                                  click: function($event) {
                                    _vm.openDownloadPhotoModal = true
                                  }
                                }
                              })
                            : _vm._e(),
                          !_vm.isPublic
                            ? _c("SendButton", {
                                staticClass: "ml-lg-3 d-lg-none",
                                on: {
                                  click: function($event) {
                                    _vm.openSendPhotoModal = true
                                  }
                                }
                              })
                            : _vm._e(),
                          !_vm.isPublic
                            ? _c("MenuButton", {
                                staticClass: "ml-lg-3 d-lg-none",
                                on: { click: _vm.handleMenuButtonClick }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-none d-lg-flex flex-grow-1 justify-content-end"
                        },
                        [
                          !_vm.isPublic
                            ? _c("SendButton", {
                                staticClass: "ml-lg-3",
                                on: {
                                  click: function($event) {
                                    _vm.openSendPhotoModal = true
                                  }
                                }
                              })
                            : _vm._e(),
                          !_vm.isPublic
                            ? _c("MenuButton", {
                                staticClass: "ml-lg-3",
                                on: { click: _vm.handleMenuButtonClick }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                ]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "viewport" },
        [
          _vm.selectedPhoto
            ? _c("ImageView", {
                attrs: { photo: _vm.selectedPhoto, scale: _vm.imageZoom },
                on: { zoomin: _vm.zoomIn, zoomout: _vm.zoomOut }
              })
            : _c(
                "div",
                {
                  staticClass: "error-messages mt-4 alert alert-danger",
                  staticStyle: {
                    "text-align": "center",
                    width: "50%",
                    "margin-top": "20% !important",
                    "margin-left": "25%"
                  }
                },
                [
                  _c("div", { staticClass: "error-messages__message" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("lang.sin_fotos_fecha.msg")) + " "
                    )
                  ])
                ]
              )
        ],
        1
      ),
      !_vm.isPublic ? _c("ProjectSelectorButton") : _vm._e(),
      _c("ImageZoom", {
        attrs: {
          orientation: "vertical",
          minValue: _vm.zoomMinValue,
          maxValue: _vm.zoomMaxValue,
          step: 1
        },
        model: {
          value: _vm.imageZoom,
          callback: function($$v) {
            _vm.imageZoom = $$v
          },
          expression: "imageZoom"
        }
      }),
      !_vm.isPublic
        ? _c("CameraSelectorDrawer", {
            attrs: {
              project: _vm.project,
              "current-camera": _vm.camera.camera,
              show: _vm.activeDrawer === "camera-drawer"
            },
            on: {
              select: _vm.selectCamera,
              close: function($event) {
                _vm.activeDrawer = ""
              }
            }
          })
        : _vm._e(),
      _c("PhotoSelectorDrawer", {
        attrs: {
          camera: _vm.camera.camera,
          date: _vm.photo.filterDate,
          show: _vm.activeDrawer === "photo-drawer",
          mostrarDiaAnterior: _vm.mostrarDiaAnterior,
          "close-on-select-photo": "",
          esPublico: _vm.isPublic
        },
        on: {
          close: function($event) {
            _vm.activeDrawer = ""
          }
        },
        model: {
          value: _vm.selectedPhoto,
          callback: function($$v) {
            _vm.selectedPhoto = $$v
          },
          expression: "selectedPhoto"
        }
      }),
      _c("SendPhotoModal", {
        attrs: { photo: _vm.selectedPhoto, open: _vm.openSendPhotoModal },
        on: {
          close: function($event) {
            _vm.openSendPhotoModal = false
          }
        }
      }),
      _c("DownloadPhotoModal", {
        attrs: { photo: _vm.selectedPhoto, open: _vm.openDownloadPhotoModal },
        on: {
          close: function($event) {
            _vm.openDownloadPhotoModal = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }