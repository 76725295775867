<template>
  <AppLayout>
    <div class="viewport">
      <div class="embed-camera" v-html="camera.camera.embed_script"></div>

      <ProjectSelectorButton />

      <CameraSelectorDrawer
        :project="project"
        :current-camera="camera.camera"
        :show="activeDrawer === 'camera-drawer'"
        @select="selectCamera"
        @close="activeDrawer = ''"
      />
    </div>

    <template v-slot:actionbar>
      <div class="d-flex flex-wrap justify-content-between flex-grow-1">
        <div class="flex-grow-1 d-md-flex justify-content-md-start">
          <TenantLogo class="d-none d-md-block" />
          
          <CameraSelectorButton
            class="d-flex flex-column ml-md-3"
            :active="activeDrawer === 'camera-drawer'"
            :camera="camera.camera"
            @click="toggleDrawer('camera-drawer')"
          />
          <div  style="margin:10px auto">
              <h6>{{$t('lang.texto_camara.msg')}}</h6>
          </div>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import CameraSelectorButton from '@/components/CameraSelectorButton.vue';
import CameraSelectorDrawer from '@/components/CameraSelectorDrawer.vue';
import ProjectSelectorButton from '@/components/ProjectSelectorButton.vue';
import TenantLogo from '@/components/TenantLogo.vue';

export default {
  name: 'CameraTimelapseView',
  components: {
    CameraSelectorButton,
    CameraSelectorDrawer,
    ProjectSelectorButton,
    TenantLogo,
  },
  data() {
    return {
      activeDrawer: '',
    };
  },
  computed: {
    ...mapState(['camera']),
    ...mapState('project', ['project']),
    ...mapGetters('project', ['projectLogoUrl']),
  },
  methods: {
    selectCamera(camera) {
      if (this.camera.camera.id !== camera.id) {
        this.$router.push({ name: 'camera-home', params: { id: camera.id } });
      }
    },
    handleMenuButtonClick() {
      this.$router.push({ name: 'profile-home', params: { project: this.project.id } });
    },
    toggleDrawer(drawer) {
      if (this.activeDrawer === drawer) {
        this.activeDrawer = '';
      } else {
        this.activeDrawer = drawer;
      }
    },
  },
};
</script>

<style lang="sass">
.viewport
  height: 100%
  width: 100%
  position: relative
  overflow: hidden
  background: #242424

.embed-camera
  height: 100%
  width: 100%
  position: relative

  > iframe
    position: absolute !important
    top: 0
    right: 0
    bottom: 0
    left: 0
    width: 100% !important
    height: 100% !important
  > div
    height: calc(100% + 26px)  
</style>
