var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppLayout",
    {
      scopedSlots: _vm._u([
        {
          key: "actionbar",
          fn: function() {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-wrap justify-content-between flex-grow-1"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex-grow-1 d-md-flex justify-content-md-start"
                    },
                    [
                      _c("TenantLogo", { staticClass: "d-none d-md-block" }),
                      _c("CameraSelectorButton", {
                        staticClass: "d-flex flex-column ml-md-3",
                        attrs: {
                          active: _vm.activeDrawer === "camera-drawer",
                          camera: _vm.camera.camera
                        },
                        on: {
                          click: function($event) {
                            return _vm.toggleDrawer("camera-drawer")
                          }
                        }
                      }),
                      _c("div", { staticStyle: { margin: "10px auto" } }, [
                        _c("h6", [
                          _vm._v(_vm._s(_vm.$t("lang.texto_camara.msg")))
                        ])
                      ])
                    ],
                    1
                  )
                ]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "viewport" },
        [
          _c("div", {
            staticClass: "embed-camera",
            domProps: { innerHTML: _vm._s(_vm.camera.camera.embed_script) }
          }),
          _c("ProjectSelectorButton"),
          _c("CameraSelectorDrawer", {
            attrs: {
              project: _vm.project,
              "current-camera": _vm.camera.camera,
              show: _vm.activeDrawer === "camera-drawer"
            },
            on: {
              select: _vm.selectCamera,
              close: function($event) {
                _vm.activeDrawer = ""
              }
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }